import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash"
import axios from 'axios';

import parse from "html-react-parser"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useLocation } from "@reach/router"
import { useContext } from "react"
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";
import ModalTiny from "react-bootstrap/Modal"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PlayVideo from '../../../components/Play/PlayVideo';
import Img01 from "../../../images/Property/img01.png"
import Img02 from "../../../images/Property/getting02.png"
import Img03 from "../../../images/Property/getting03.png"
import NoImage from "../../../images/pittis-no-image.png"
import MortgageLogo from "../../../images/area-guide/MMD-1.svg"
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import MortgageCalc from "../../calculators/mortgagecalc/mortgage-property-details"
import LocRatingMap from '../../map/loc-rating-map-property-details';
import BudgetBooster from "../../BudgetBooster"
import StampDuty from "../../calculators/stampdutycalc"
import Yield_Calculator from "../../calculators/rentalyieldcalc"
import BookViewForm from "../../forms/book-a-viewing-form"
import ShareFrndForm from "../../forms/send-friend-form"
import RoomDetails from "../RoomDetails/RoomDetails"
import PropertyHelp from "../Help"
import { AREA_GUIDE_PAGE_URL } from '../../common/site/constants'


import "./ViewPage.scss"
import "../Description/Description.scss"
import "../Payment/Payment.scss"
import "../Info/Info.scss"

const ViewPage = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [colour, setColour] = useState(false);
    const [savetype, setSaveType] = useState(false);
    const [smallimgcount, setSmaillImgCount] = useState(5);
    const [wishlistproperties, setWishlistProperties] = useState(null);
    const [loginstatus, setLoginStatus] = useState('')
    const [addresponse, setAddResponse] = useState('')

    const [show, setShow] = useState(false);
    const showMenu = () => {
        setShow(!show);
    };


    // Area Guide


    // Negotiator Details get

    // const [propItems, setPropItems] = useState(false)
    // const getitems = async url => {
    //     try {
    //         const { data } = await axios.get(url, {
    //             headers: {
    //               Authorization:
    //                 `Bearer ${process.env.GATSBY_STRAPI_FEED_TOKEN}`,
    //             },
    //         })
    //         setPropItems(data)
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    const getwishlistproperty = url => {
        fetch(url, {
            method: 'GET',
            // mode: "no-cors",
            redirect: 'follow',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                "Content-Type": "application/json",
            },
            credentials: 'include'
        }).then(response => response.text())
            .then(result => {
                setWishlistProperties(result);
                if (_.includes(result, props.propertyData.crm_id)) {
                    setSaveType(true)
                }
            })
            .catch(error => console.log('error', error));
    }



    const wishlistpropertyadd = async url => {

        var raw = JSON.stringify("remove");
        try {
            fetch(url, {
                method: 'PUT',
                // mode: "no-cors",
                redirect: 'follow',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                    "Content-Type": "application/json",
                },
                body: savetype ? raw : '',
                credentials: 'include'
            }).then(response => response.text())
                .then(result => { setAddResponse(result) })
                .catch(error => console.log('error', error));

            // setLoginStatus(data.text())
        } catch (error) {
            console.error(error)
        }

    }


    useEffect(() => {
        let area = props.propertyData.area ?? "";
        // let url = process.env.GATSBY_STRAPI_FEED_SRC + `/branches?branch_id=${props.propertyData.office_crm_id}&_where[publish]=true`; // TODO: base URL supposed to be from .env
        // getitems(url)


        setLoginStatus(localStorage.getItem('myaccount-login-status'))

        if (localStorage.getItem('myaccount-login-status')) {
            getwishlistproperty(`https://myaccount.pittis.co.uk/api/propertyshortlist/`)
        }

    }, [])
    // Negotiator Details get

    var login = ''
    if (localStorage) {
        login = localStorage.getItem('myaccount-login-status')
    }

    const changeColour = () => {
        setColour(!colour);
        if (savetype) {
            wishlistpropertyadd(`https://myaccount.pittis.co.uk/api/propertyshortlist/${props.propertyData.crm_id}`)
            setSaveType(false)
        }
        else {
            wishlistpropertyadd(`https://myaccount.pittis.co.uk/api/propertyshortlist/${props.propertyData.crm_id}`)
            setSaveType(true)
        }
    }

    const data = useStaticQuery(graphql`
  query {
    allBranches(filter: {publish: {eq: true}}) {
        totalCount
        edges {
        node {
            branch_id
            branch_name
            address {
                street
                town
                county
                postcode
                district
            }
            sales_dept {
              phone
              manager {
                image
                job_title
                name
              }
            }
            lettings_dept {
              phone
              manager {
                image
                job_title
                name
              }
            }
        }
        }
    }
    glstrapi {
        areaGuides(where: {Publish: true}) {
            id
            Name
            Short_Description
            URL
            Tile_Image {
              alternativeText
              url
            }
        }
    }
  }
`);

    const areaguides = data.glstrapi.areaGuides;
    const allbranchlist = data.allBranches.edges;


    let filtered_branch = _.filter(allbranchlist, function (o) { return o.node.branch_id === props.propertyData.office_crm_id; });

    let main_area = areaguides.filter(item => _.includes(item.Name, props.propertyData.area)).pop();
    var foo_phone = ''
     var branch_address = ''
    if (filtered_branch.length > 0) {
        if (props.propertyData.search_type == "sales" && filtered_branch[0].node?.sales_dept && filtered_branch[0].node?.sales_dept?.phone) {
            foo_phone = filtered_branch[0].node?.sales_dept?.phone
        }
        else if (props.propertyData.search_type == "lettings" && filtered_branch[0].node?.lettings_dept && filtered_branch[0].node?.lettings_dept?.phone) {
            foo_phone = filtered_branch[0].node?.lettings_dept?.phone
        }
        if(filtered_branch[0].node?.address){
            let addr = filtered_branch[0].node?.address;
            if (addr?.building) {
                branch_address = addr.building + ', '
              }
              if (addr?.street) {
                branch_address += addr?.street + ', '
              }
              if (addr?.town) {
                branch_address += addr?.town + ', '
              }
              if (addr?.district) {
                branch_address += addr?.district + ', '
              }
              if (addr?.postcode) {
                branch_address += addr?.postcode
              }
        }

    }

    // Area Guide

    const propertyImages = props.propertyData.images
    const propertyImagesFloor = props.propertyData.floorplan

    // ggfx
    let processedImages = JSON.stringify({});
    if (props.propertyData?.imagetransforms?.images_Transforms) {
        processedImages = props.propertyData.imagetransforms.images_Transforms;
    }
    // ggfx



    const [modalSendfrndformOpen, setSendfrndformOpen] = React.useState(false);
    const openSendfrndformModal = () => {
        setSendfrndformOpen(true);
    }
    const closeSendfrndformModal = () => {
        setSendfrndformOpen(false);
    }

    const [modalSharefrndformOpen, setSharefrndformOpen] = React.useState(false);
    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openPropertyImage = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }
    // Property images lightbox

    if (propertyImages.length > 0) {
        var img_1 = propertyImages[0].url
    }

    const location = useLocation();

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const [Shareicons, setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if (Shareicons === true) {
            setShareicons(false);
        }
    }
    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event,
            'formId': shareurl,
            'formName': 'Share',
            'formLabel': 'Share Property'
        });
    }
    // Property details images lightbox
    var propertyLightImages1 = propertyImages && propertyImages.map(img => img.url);
    var propertyLightImagesCaption1 = propertyImages && propertyImages.map(img => img.caption);

    // Removing undefined in array
    var propertyLightImages = propertyLightImages1.filter(elm => elm);
    var propertyLightImagesCaption = propertyLightImagesCaption1.filter(elm => elm);
    // Removing undefined in array
    if (propertyImagesFloor && propertyImagesFloor.length > 0) {
        propertyLightImages = [propertyImagesFloor[0].url].concat(propertyLightImages);
        propertyLightImagesCaption = ["Floorplan"].concat(propertyLightImagesCaption1);
    }

    // Property details images lightbox


    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat'] = props.propertyData.latitude
    mapItem['lng'] = props.propertyData.longitude
    mapItem['id'] = props.propertyData.id
    const mapMarkerImageShowFlag = 0;
    mapItems.push(mapItem);

    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-up" : "icon-down"
                    }
                ></i>
            </button>
        )
    }

    const promo = _.includes(props.propertyData.extra?.property_promotions ? props.propertyData.extra?.property_promotions : "", "1009")


    const pageurl = typeof window !== 'undefined' ? window.location.href : ''

    var searchtype = props.propertyData.search_type
    if (promo) {
        searchtype = 'lettings'
    }

    return (
        <React.Fragment>
            <section className="view-page">
                <Container>
                    <Row>
                        <Col lg={7} xl={8}>
                            <div className="view-details">
                                <h1 className="d-none">{props.propertyData.display_address ? props.propertyData.display_address : props.propertyData.address.display_address}</h1>
                                {props.propertyData?.price_qualifier === "POA" ? <span className="price"><span>Price on Application</span></span> : <span className="price">
                                    £{props.propertyData && props.propertyData.price.toLocaleString()} {props.propertyData.search_type == "lettings" ? 'PCM' : ''} <span>{props.propertyData.price_qualifier != "Default" ? props.propertyData.price_qualifier : ''}</span>
                                </span>}
                                <h2>{props.propertyData.title}</h2>
                                <span className="sm-info">{props.propertyData.display_address ? props.propertyData.display_address : props.propertyData.address.display_address}</span>
                                <div className="icons-wrapper">
                                    {props.propertyData.bedroom ?
                                        <div className="icons">
                                            <span>
                                                <i className="bedroom">
                                                </i>
                                            </span>
                                            <span className="text d-md-none">{props.propertyData.bedroom} Bed
                                            </span>
                                            <span className="text d-none d-md-block">{props.propertyData.bedroom} {props.propertyData.bedroom < 2 ? 'Bedroom' : 'Bedrooms'}
                                            </span>
                                        </div> : ''
                                    }
                                    {props.propertyData.bathroom ?
                                        <div className="icons">
                                            <span>
                                                <i className="bathroom">
                                                </i>
                                            </span>
                                            <span className="text d-md-none">{props.propertyData.bathroom} Bath</span>
                                            <span className="text d-none d-md-block">{props.propertyData.bathroom} {props.propertyData.bathroom < 2 ? 'Bathroom' : 'Bathrooms'}</span>
                                        </div> : ''
                                    }
                                    {props.propertyData.reception ?
                                        <div className="icons">
                                            <span>
                                                <i className="reception">
                                                </i>
                                            </span>
                                            <span className="text ">{props.propertyData.reception} {props.propertyData.reception < 2 ? 'Reception' : 'Receptions'}</span>
                                        </div> : ''
                                    }
                                </div>
                                <div className="top-icons">
                                    {login == "true" ?
                                        <div className="icons" onClick={changeColour}>
                                            <Link className="like-icons">
                                                <i className={savetype ? "fill-like" : "like"}>
                                                </i>
                                            </Link>
                                            <span className="text">{savetype ? "Saved" : "Save"}</span>
                                        </div> :
                                        <a className="icons" href={`https://myaccount.pittis.co.uk/Account/MainSiteLogin/?returnUrl=${pageurl}&id=${props.propertyData.crm_id}`}>
                                            <Link className="like-icons">
                                                <i className={savetype ? "fill-like" : "like"}>
                                                </i>
                                            </Link>
                                            <span className="text">Save</span>
                                        </a>
                                    }

                                    <div className="icons">
                                        <a href="javascript:void(0)" onClick={openShareicons}>
                                            <i className="share">
                                            </i>
                                        </a>
                                        <span className="text" onClick={openShareicons}>Share</span>
                                        {Shareicons &&
                                            <div onClick={openShareicons} className="property-share-social-icons mobile-details-socila-share">
                                                <FacebookShareButton onClick={() => trackerShare('Facebook')} url={shareurl} className="my-share-button facebook-share">
                                                    <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                                </FacebookShareButton>
                                                <TwitterShareButton onClick={() => trackerShare('Twitter')} url={shareurl} className="my-share-button twitter-share">
                                                    <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                                </TwitterShareButton>
                                                <LinkedinShareButton onClick={() => trackerShare('Linkedin')} url={shareurl} className="my-share-button linkedin-share">
                                                    <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                                </LinkedinShareButton>
                                                <WhatsappShareButton onClick={() => trackerShare('Whatsapp')} url={shareurl} className="my-share-button whatsapp-share">
                                                    <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                                </WhatsappShareButton>
                                                <EmailShareButton url='#' onClick={() => { openSharefrndformModal(); trackerShare('Email') }} className="my-share-button email-share">
                                                    <EmailIcon size={32} round={false} borderRadius={`10`} />
                                                </EmailShareButton>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className={`gallery-wrapper ${propertyLightImages.length < 5 ? 'img-wrap' : ''}`}>
                                    {
                                        propertyLightImages && propertyLightImages.slice(0, isMobile ? 3 : 5).map((node, i) => {
                                            let processedImages = JSON.stringify({});
                                            if (props.propertyData?.imagetransforms?.images_Transforms) {
                                                processedImages = props.propertyData.imagetransforms.images_Transforms;
                                            }

                                            return (
                                                <div className="gallery-img" onClick={(e) => openPropertyImage(e, i)}>
                                                    <picture>
                                                        {node &&
                                                            <ImageTransform
                                                                imagesources={node}
                                                                renderer="srcSet"
                                                                imagename="property.images.detail1"
                                                                ggfxName="FEED"
                                                                attr={{ alt: '', class: '' }}
                                                                imagetransformresult={processedImages}
                                                                id={props.propertyData.id}
                                                            />}
                                                    </picture>
                                                    {/* <button type="button" className="btn-play d-none d-xl-flex" onClick={(e) => { setPlay(true) }}>
                                                    <i className="icon-play"></i>
                                                </button> */}
                                                </div>
                                            )
                                        })}
                                    {propertyLightImages.length > 5 &&
                                        <div className="total property-total-wrapper" onClick={(e) => openPropertyImage(e, 0)}>
                                            +{propertyLightImages.length - 5}
                                        </div>
                                    }
                                </div>
                            </div>
                            {props.propertyData.extra && props.propertyData.extra.features &&
                                <div className="features">
                                    <h2>Key Features</h2>
                                    <ul className="features-list ">
                                        {props.propertyData.extra.features.map((node, i) => {
                                            return (
                                                <li>
                                                    {node}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            }

                            {props.propertyData.long_description &&
                                <div className="description">
                                    <h2>Description</h2>
                                    <div className={`${show ? " " : "para-content"} ${(props.propertyData.long_description).length < 350 ? 'height' : ''}`}  >
                                        <p>
                                            {parse(props.propertyData.long_description)}
                                        </p>
                                    </div>
                                    {(props.propertyData.long_description).length > 450 &&
                                        <div className={show ? "more-content fixed" : "more-content"} onClick={showMenu} >
                                            <span onClick={showMenu}>
                                                <i className={show ? "icon-minus-dark " : "icon-plus "} ></i>
                                                <Link className="more">
                                                    {show ? "Read Less" : "Read More"}
                                                </Link>
                                            </span>
                                        </div>
                                    }

                                </div>
                            }

                            {props.propertyData.shared_ownership || props.propertyData.shared_ownership_percentage || props.propertyData.shared_ownership_rent || props.propertyData.shared_ownership_rent_freq || props.propertyData.annual_ground_rent || props.propertyData.ground_rent_review_period_years || props.propertyData.ground_rent_percentage_increase || props.propertyData.annual_service_charge || props.propertyData.council_tax_exempt || props.propertyData.council_tax_band || props.propertyData.tenure_unexpired_years ?
                                <div className="features">
                                    <h2>Important information about this property</h2>
                                    <ul class="features-list ">

                                        {props.propertyData.shared_ownership ?
                                            <li>Shared Ownership: yes</li> : <li>Shared Ownership: no</li>}

                                        {props.propertyData.shared_ownership_percentage ?
                                            <li>Housing Association share: {props.propertyData.shared_ownership_percentage}%</li> : ''}

                                        {props.propertyData.shared_ownership_rent ?
                                            <li>Housing Association rent: £{props.propertyData.shared_ownership_rent}</li> : ''}

                                        {props.propertyData.shared_ownership_rent_freq ?
                                            <li>Housing Association rent frequency: {props.propertyData.shared_ownership_rent_freq}</li> : ''}

                                        {props.propertyData.annual_ground_rent ?
                                            <li>Annual ground rent: £{props.propertyData.annual_ground_rent}</li> : ''}

                                        {props.propertyData.ground_rent_review_period_years ?
                                            <li>Ground rent review period: {props.propertyData.ground_rent_review_period_years} years</li> : ''}

                                        {props.propertyData.ground_rent_percentage_increase ?
                                            <li>Ground rent percentage increase: {props.propertyData.ground_rent_percentage_increase}%</li> : ''}

                                        {props.propertyData.annual_service_charge ?
                                            <li>Annual Service Charge: £{props.propertyData.annual_service_charge}</li> : ''}

                                        {props.propertyData.council_tax_exempt ?
                                            <li>Council Tax exempt: yes</li> : <li>Council Tax exempt: no</li>}

                                        {props.propertyData.council_tax_band ?
                                            <li>Council Tax band: {props.propertyData.council_tax_band}</li> : ''}

                                        {props.propertyData.tenure_unexpired_years ?
                                            <li>Tenure Unexpired: {props.propertyData.tenure_unexpired_years} years</li> : ''}

                                    </ul>
                                </div> : ''
                            }

                            {props.propertyData.room_details?.length > 0 &&
                                <RoomDetails {...props.propertyData} />
                            }

                            {props.propertyData.search_type == "sales" &&
                                <PropertyHelp />
                            }
                            <div className="locrating-map">
                                <h3>{props.propertyData.display_address ? props.propertyData.display_address : props.propertyData.address.display_address}</h3>
                                <p>Use the map to toggle between tabs and discover more about this area including local school information, broadband speed and sold prices.</p>
                                <LocRatingMap data={mapItems} />
                            </div>

                            <div className="mobile-sidebar-property-details">
                                <div className="details-box">
                                    {filtered_branch.length > 0 ?
                                        <React.Fragment>
                                            {props.propertyData.search_type == "sales" && filtered_branch[0].node?.sales_dept &&
                                                <div className="contact-wrapper mt-0">
                                                    {/* {JSON.stringify(filtered_branch[0].node?.sales_dept.manager.name)} */}
                                                    <div className="contact-img">
                                                        <img src={filtered_branch[0].node?.sales_dept?.manager?.image} alt={filtered_branch[0].node?.sales_dept?.manager?.name} />
                                                    </div>
                                                    <div className="contact-info">
                                                        <h3>{filtered_branch[0].node?.sales_dept?.manager?.name}</h3>
                                                        <span className="profile">{filtered_branch[0].node?.sales_dept?.manager?.job_title}</span>
                                                        <div className="icon-wrap d-flex">
                                                            <i className="icon-phone"></i>
                                                            <a href={`tel:${filtered_branch[0].node?.sales_dept?.phone}`} className="tel">
                                                                {filtered_branch[0].node?.sales_dept?.phone}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {props.propertyData.search_type == "lettings" && filtered_branch[0].node?.lettings_dept &&
                                                <div className="contact-wrapper mt-0">
                                                    {/* {JSON.stringify(filtered_branch[0].node?.sales_dept.manager.name)} */}
                                                    <div className="contact-img">
                                                        <img src={filtered_branch[0].node?.lettings_dept?.manager?.image} alt={filtered_branch[0].node?.lettings_dept?.manager?.name} />
                                                    </div>
                                                    <div className="contact-info">
                                                        <h3>{filtered_branch[0].node?.lettings_dept?.manager?.name}</h3>
                                                        <span className="profile">{filtered_branch[0].node?.lettings_dept?.manager?.job_title}</span>
                                                        <div className="icon-wrap d-flex">
                                                            <i className="icon-phone"></i>
                                                            <a href={`tel:${filtered_branch[0].node?.lettings_dept?.phone}`} className="tel">
                                                                {filtered_branch[0].node?.lettings_dept?.phone}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </React.Fragment> :
                                        <div className="empty"></div>
                                    }
                                    <span className="use mb-4">
                                        <span className="highlight">Thinking of moving home?</span>
                                        Use our <Link className="dark" to="/sell-your-property/property-valuation"> instant valuation tool </Link> and see what your home is worth in seconds
                                    </span>
                                    {(promo && props.propertyData.extra?.yield) || (promo && props.propertyData.extra?.rental_income) ?
                                        <div className="investment-opportunity">
                                            <h4>Investment opportunity</h4>
                                            {props.propertyData.extra?.yield &&
                                                <div className="estimated-yield">
                                                    <p>Estimated yield: <b>{props.propertyData.extra?.yield}% yield</b></p>
                                                </div>
                                            }
                                            {props.propertyData.extra?.rental_income &&
                                                <div className="estimated-yield last">
                                                    <p>Estimate rental income: <b>£{props.propertyData.extra?.rental_income.toLocaleString()} pcm</b></p>
                                                </div>
                                            }
                                            <Link to="/investor-questions" className="qus-link">Ask the lettings experts a question</Link>
                                        </div> : ''}

                                </div>
                            </div>

                            {props.propertyData.search_type == "sales" &&
                                <div className="payment">
                                    <div className="repayment">
                                        <div className="title">
                                            <i className="icon-house"></i>
                                            <h2>What could my mortgage repayments be on this home?</h2>
                                        </div>
                                        <div className="form-wrapper">
                                            <MortgageCalc propertyPrice={props.propertyData.price} />
                                            <Row className="bottom-details">
                                                <Col lg={8} className="main-1">
                                                    <Link to="/branchfinder" onClick={() => { localStorage.setItem('page-alias', "contact-form-mortgages") }} className="btn btn-primary first">
                                                        Speak to us
                                                    </Link>
                                                    <Link to="/branchfinder" onClick={() => { localStorage.setItem('page-alias', "contact-form-mortgages") }} className="apply">Ready to apply? <u>Speak to one of our advisors</u></Link>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="main-2">
                                                        Powered by
                                                        <img src={MortgageLogo} alt="Mortgage Matters" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <BudgetBooster price={props.propertyData.price} />
                                    <div className="sale-info">
                                        <div className="heading">
                                            <i className="icon-hammer"></i>
                                            <h2>No Sale, No Fee Conveyancing</h2>
                                        </div>
                                        <p>We provide fixed-fee residential conveyancing, designed especially for you, with no hidden costs and qualified solicitors available until 8pm.</p>
                                        <Link to="/buy-property/conveyancing-advice" className="btn btn-primary">
                                            Find out more
                                        </Link>
                                    </div>
                                </div>
                            }
                            {props.propertyData.search_type == "sales" &&
                                <div className="info">
                                    <h2>Good to know</h2>
                                    <Accordion >
                                        <Card>
                                            <div className="accordion-header card-header">
                                                <ContextAwareToggle eventKey="0" className="accordion-header">
                                                    <div className="heading">
                                                        <i className="icon-calculator"></i>
                                                        <h4>How much stamp duty will I need to pay on this home?</h4>
                                                    </div>

                                                </ContextAwareToggle>
                                            </div>
                                            <Accordion.Collapse eventKey="0">
                                                <div className="accordion-card accordion-card-first card-body">
                                                    <div className="accordion-list">
                                                        <div className="list-wrapper">
                                                            <StampDuty propertyValue={props.propertyData.price} tag="details" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <div className="accordion-header card-header">
                                                <ContextAwareToggle eventKey="1" className="accordion-header">
                                                    <div className="heading">
                                                        <i className="icon-calculator"></i>
                                                        <h4>How much could I earn as a landlord for this property?</h4>
                                                    </div>

                                                </ContextAwareToggle>
                                            </div>
                                            <Accordion.Collapse eventKey="1">
                                                <div className="accordion-card accordion-card-first card-body">
                                                    <div className="accordion-list">
                                                        <div className="list-wrapper">
                                                            <Yield_Calculator propertyPrice={props.propertyData.price} tag="details" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    {main_area &&
                                        <div className="getting">
                                            <div className="getting-img">
                                                <picture>
                                                    <img className="bg-img" src={main_area.Tile_Image.url} alt={props.propertyData.area} />
                                                </picture>
                                                {/* <button type="button" className="btn-play" onClick={(e) => { setPlay(true) }}>
                                            <i className="icon-play"></i>
                                        </button> */}
                                            </div>
                                            <div className="details">
                                                <span className="sm-text">Getting to know</span>
                                                <h2>{props.propertyData.area}</h2>
                                                <p>{main_area.Short_Description}</p>
                                                <div className="link-wrapper">
                                                    <Link to={`${AREA_GUIDE_PAGE_URL.alias}${main_area.URL}/`} className="view">View the {props.propertyData.area} Area Guide</Link>
                                                    <Link to={`${AREA_GUIDE_PAGE_URL.alias}${main_area.URL}/`}>
                                                        <i className="icon-arrow"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </Col>
                        <Col lg={5} xl={4} className="d-none d-lg-block">
                            <div className="details-box">
                                {filtered_branch.length > 0 ?
                                    <React.Fragment>
                                        {props.propertyData.search_type == "sales" && filtered_branch[0].node?.sales_dept &&
                                            <div className="contact-wrapper mt-0">
                                                {/* {JSON.stringify(filtered_branch[0].node?.sales_dept.manager.name)} */}
                                                <div className="contact-img">
                                                    <img src={filtered_branch[0].node?.sales_dept?.manager?.image} alt={filtered_branch[0].node?.sales_dept?.manager?.name} />
                                                </div>
                                                <div className="contact-info">
                                                    <h3>{filtered_branch[0].node?.sales_dept?.manager?.name}</h3>
                                                    <span className="profile">{filtered_branch[0].node?.sales_dept?.manager?.job_title}</span>
                                                    <div className="icon-wrap d-flex">
                                                        <i className="icon-phone"></i>
                                                        <a href={`tel:${filtered_branch[0].node?.sales_dept?.phone}`} className="tel">
                                                            {filtered_branch[0].node?.sales_dept?.phone}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {props.propertyData.search_type == "lettings" && filtered_branch[0].node?.lettings_dept &&
                                            <div className="contact-wrapper">
                                                {/* {JSON.stringify(filtered_branch[0].node?.sales_dept.manager.name)} */}
                                                <div className="contact-img mt-0">
                                                    <img src={filtered_branch[0].node?.lettings_dept?.manager?.image} alt={filtered_branch[0].node?.lettings_dept?.manager?.name} />
                                                </div>
                                                <div className="contact-info">
                                                    <h3>{filtered_branch[0].node?.lettings_dept?.manager?.name}</h3>
                                                    <span className="profile">{filtered_branch[0].node?.lettings_dept?.manager?.job_title}</span>
                                                    <div className="icon-wrap d-flex">
                                                        <i className="icon-phone"></i>
                                                        <a href={`tel:${filtered_branch[0].node?.lettings_dept?.phone}`} className="tel">
                                                            {filtered_branch[0].node?.lettings_dept?.phone}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment> :
                                    <div className="empty"></div>
                                }
                                <span className="use mb-4">
                                    <span className="highlight">Thinking of moving home?</span>
                                    Use our <Link className="dark" to="/sell-your-property/property-valuation"> instant valuation tool </Link> and see what your home is worth in seconds
                                </span>
                                <a href="javascript:void(0)" className="btn btn-primary first" onClick={openSendfrndformModal}>
                                    Enquire or Book a Viewing
                                </a>
                                {/* <Link className="btn btn-primary"> */}

                                {filtered_branch.length > 0 ?
                                    <React.Fragment>
                                        {props.propertyData.search_type == "sales" && filtered_branch[0].node?.sales_dept && filtered_branch[0].node?.sales_dept?.phone &&
                                            <a href={`tel:${filtered_branch[0].node?.sales_dept?.phone}`} className="btn btn-primary tel upper">Call {filtered_branch[0].node?.sales_dept?.phone}
                                            </a>
                                        }
                                        {props.propertyData.search_type == "lettings" && filtered_branch[0].node?.lettings_dept && filtered_branch[0].node?.lettings_dept?.phone &&
                                            <a href={`tel:${filtered_branch[0].node?.lettings_dept?.phone}`} className="btn btn-primary tel upper">Call {filtered_branch[0].node?.lettings_dept?.phone}
                                            </a>
                                        }
                                    </React.Fragment>
                                    : ''}

                                {/* <a href="tel:+01895647565" className="tel upper">
                                        01895 647565
                                    </a> */}
                                {/* </Link> */}

                                {(promo && props.propertyData.extra?.yield) || (promo && props.propertyData.extra?.rental_income) ?
                                    <div className="investment-opportunity">
                                        <h4>Investment opportunity</h4>
                                        {props.propertyData.extra?.yield &&
                                            <div className="estimated-yield">
                                                <p>Estimated yield: <b>{props.propertyData.extra?.yield}% yield</b></p>
                                            </div>
                                        }
                                        {props.propertyData.extra?.rental_income &&
                                            <div className="estimated-yield last">
                                                <p>Estimate rental income: <b>£{props.propertyData.extra?.rental_income.toLocaleString()} pcm</b></p>
                                            </div>
                                        }
                                        <Link to="/investor-questions" className="qus-link">Ask the lettings experts a question</Link>
                                    </div> : ''}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Property image lightbox */}
            {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImages[photoIndex]}
                    imageTitle={propertyLightImagesCaption[photoIndex] + ' - ' + props.propertyData.display_address}
                    nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
                    prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                    }
                />
            )}
            {/* Property image lightbox */}

            <div className="btn-wrapper property-details-floating-cta-fixed">
                <button className="btn btn-secondary" onClick={openSendfrndformModal}>Book a Viewing</button>
                <a className="btn btn-primary" href={`tel:${foo_phone}`}>Call us</a>
            </div>


            <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="book-a-viewing-modal modal-main-form modal">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">Book a Viewing</h2></ModalTiny.Title>
                </ModalTiny.Header>

                <ModalTiny.Body className="people-details-form-modal event-section">
                    <BookViewForm property_img={img_1} crm_id={props.propertyData.crm_id} page_url={shareurl} property_title={props.propertyData.display_address} type={props.propertyData.search_type} address={props.propertyData.address} />
                </ModalTiny.Body>

            </ModalTiny>

            <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="book-a-viewing-modal modal-main-form modal">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">Share to a Friend</h2></ModalTiny.Title>
                </ModalTiny.Header>

                <ModalTiny.Body className="people-details-form-modal event-section">
                    <ShareFrndForm property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address} branch_address={branch_address}  type={props.propertyData.search_type} address={props.propertyData.address} />
                </ModalTiny.Body>

            </ModalTiny>
        </React.Fragment>
    )
}
export default ViewPage
